import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import MatchTileHits from "@/game-destiny2/components/MatchTileHits.jsx";
import MatchTileScoreboard from "@/game-destiny2/components/MatchTileScoreboard.jsx";
import MatchTileStatistics from "@/game-destiny2/components/MatchTileStatistics.jsx";
import { ACTIVITY_MODE } from "@/game-destiny2/constants/activity-modes.mjs";
import { CLASS_BY_BE_ENUM } from "@/game-destiny2/constants/class.mjs";
import { useMatchAggregates } from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import { useMatchListFilter } from "@/game-destiny2/utils/use-match-list-filter.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import { dataIsLoaded } from "@/util/wiki.mjs";

export default function Match() {
  const {
    parameters: [bungieId, _seasonId, gameId],
  } = useRoute();
  const { t } = useTranslation();
  const {
    destiny2: {
      profiles,
      match: matchMap,
      wiki: { activities },
    },
  } = useSnapshot(readState);
  const profile = useMemo(
    () => filterErrState(profiles[bungieId]),
    [profiles, bungieId],
  );

  const match = matchMap[gameId];
  const characterIds = useMemo(
    () => profile?.guardians.map((g) => g.characterId),
    [profile],
  );
  const playerMatch = match?.playerMatches?.find((m) =>
    characterIds?.includes(m.guardian.characterId),
  );
  const [_error, matchlist] = useMatchListFilter(bungieId, characterIds);

  const last20Matchlist = useMemo(
    () => matchlist?.filter(({ id }) => id !== match.id),
    [match?.id, matchlist],
  );
  const last20Aggregates = useMatchAggregates(last20Matchlist);

  const { map, difficulty, activityModeEnum, iconUri } = activities[
    match?.referenceId
  ] ?? {
    map: t("destiny2:unknownActivity", "Unknown Activity"),
  };
  const playerClass = CLASS_BY_BE_ENUM[playerMatch?.guardian.class];
  const mode = ACTIVITY_MODE[activityModeEnum ?? match?.mode];
  const iconSrc = iconUri ?? mode?.iconUri;
  return (
    <SharedMatchLayout
      match={match}
      handleLoading={false}
      title={
        typeof playerMatch?.won !== "undefined"
          ? playerMatch.won
            ? t("common:stats.victory", "Victory")
            : t("common:stats.defeat", "Defeat")
          : t("common:results.draw", "Draw")
      }
      image={iconSrc ? `${appURLs.BUNGIE}${iconSrc}` : FALLBACK_IMAGE_URL}
      imageLink={`/destiny2/profile/${bungieId}`}
      borderColor="var(--shade3)"
      underTitle={
        match &&
        dataIsLoaded(activities) && (
          <div className="flex align-center gap-2 color-shade2">
            {[
              difficulty,
              mode && t(...mode.label),
              map,
              playerClass && t(...playerClass.label),
            ]
              .filter(Boolean)
              .map((i) => (
                <React.Fragment key={i}>
                  <span className="type-callout color-shade2">{i}</span>
                  <DotIcon style={{ color: "var(--shade3)" }} />
                </React.Fragment>
              ))}
            {!!match?.startedAt && (
              <div className="type-callout color-shade2">
                <TimeAgo
                  date={new Date(match?.startedAt)}
                  className={undefined}
                />
              </div>
            )}
          </div>
        )
      }
    >
      <MainColumnsContainer className={matchRefs.columnsContainerClassName?.()}>
        <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <MatchTileScoreboard
            last20Aggregates={last20Aggregates}
            match={match}
            playerMatch={playerMatch}
          />
        </div>
        <div className="span-2">
          <MatchTileHits
            last20Aggregates={last20Aggregates}
            match={match}
            playerMatch={playerMatch}
          />
          {matchRefs.InContentBanner && <matchRefs.InContentBanner />}
          <MatchTileStatistics
            last20Aggregates={last20Aggregates}
            match={match}
            playerMatch={playerMatch}
          />
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

export function meta([profileId]) {
  const profile = readState.destiny2.profiles[profileId];
  const name =
    !profile || profile instanceof Error ? "Unknown" : profile.bungieName;
  return {
    title: [
      "destiny2:meta.match.title",
      "{{name}}'s Match Stats – D2 – Blitz Destiny 2",
      { name },
    ],
    description: [
      "destiny2:meta.match.description",
      "Match Stats for {{name}}",
      { name },
    ],
  };
}
